import { useGetPassengersConfigQuery } from '@codegen/cmsUtils';
import { passengerTitlesWithGenderType } from '@web/utils/partnerUtils';
import { useConstants } from '../ConstantContext';

const usePartnerPassengersConfig = () => {
  const { locale, partner } = useConstants();

  const { data } = useGetPassengersConfigQuery(
    {
      partner,
      locale,
    },
    { retry: 2 },
  );

  return {
    passengerRules: data?.partner?.passengersConfig?.passengerRules,
    passengerTitles: passengerTitlesWithGenderType(
      data?.partner?.passengersConfig?.passengerTitles || [],
    ),
    passengerGenders: data?.partner?.passengersConfig?.passengerGenders,
  };
};

export default usePartnerPassengersConfig;
