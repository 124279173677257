import { ThemeV4Fragment } from '@codegen/cmsUtils';
import { ThemeColours } from '@ui-v2/types/colours';

export const buildColours = (theme: ThemeV4Fragment): ThemeColours => {
  const { colours } = theme;

  return {
    components: {
      date: {
        text: {
          default: colours.dateTextDefault,
          disabled: colours.dateTextDisabled,
          selected: colours.dateTextSelected,
        },
      },
      tooltip: {
        text: colours.tooltipTextDefault,
        surface: colours.tooltipSurfaceDefault,
      },
      dateCarousel: {
        surface: {
          active: colours.dateCarouselSurfaceActive,
          default: colours.dateCarouselSurface,
          hover: colours.dateCarouselSurfaceHover,
        },
        text: {
          date: colours.dateCarouselTextDate,
          price: colours.dateCarouselTextPrice,
        },
        border: {
          divider: colours.dateCarouselBorderDivider,
        },
        icon: colours.dateCarouselIcon,
      },
      slider: {
        interactive: {
          fill: colours.sliderInteractiveFill,
          handle: colours.sliderInteractiveHandle,
          track: colours.sliderInteractiveTrack,
        },
      },
      header: {
        surface: colours.headerSurface,
        text: colours.headerText,
      },
      itineraryCard: {
        surface: {
          selected: colours.itineraryCardSurfaceSelected,
        },
        text: {
          accent: colours.itineraryCardTextAccent,
        },
      },
      navBar: {
        surface: {
          default: colours.navBarSurfaceDefault,
        },
        text: colours.navBarText,
      },
      search: {
        interactive: {
          default: colours.searchInteractiveDefault,
          hover: colours.searchInteractiveHover,
          pressed: colours.searchInteractivePressed,
        },
        surface: {
          default: colours.searchSurfaceDefault,
        },
        text: {
          on: {
            interactive: colours.searchTextOnInteractive,
          },
          toggle: {
            active: colours.searchTextToggleActive,
          },
        },
      },
      toggle: {
        interactive: {
          default: colours.interactiveToggleDefault,
          hover: colours.interactiveToggleHover,
          active: colours.interactiveToggleActive,
        },
      },
      basket: {
        surface: {
          primary: colours.basketSurfacePrimary,
          secondary: colours.basketSurfaceSecondary,
        },
        border: {
          default: colours.basketBorderDefault,
        },
      },
      footer: {
        surface: colours.footerSurface,
        text: colours.footerText,
      },
      seat: {
        border: {
          exitsign: colours.seatBorderExitSign,
        },
        surface: {
          exitsign: colours.seatSurfaceExitSign,
        },
        text: {
          exitsign: colours.seatTextExitSign,
        },
      },
      tieredConnection: {
        surface: { main: colours.tieredConnectionSurfaceMain },
        border: { default: colours.tieredConnectionBorderDefault },
        interactive: {
          hover: colours.tieredConnectionSecondaryHover,
          default: colours.tieredConnectionSecondaryDefault,
        },
        icon: colours.tieredConnectionIcon,
      },
      applePay: {
        surface: {
          light: '#fff',
          dark: '#000',
        },
        text: {
          on: {
            light: '#000',
            dark: '#fff',
          },
        },
      },
    },
    brand: {
      primary: colours.brandPrimary,
      secondary: colours.brandSecondary,
      tertiary: colours.brandTertiary,
      accent: colours.brandAccent,
    },
    background: {
      main: colours.backgroundMain,
      backdrop: colours.backgroundBackdrop,
    },
    breadcrumbs: {
      border: {
        active: colours.breadcrumbsBorderActive,
        completed: colours.breadcrumbsBorderCompleted,
        incomplete: colours.breadcrumbsBorderIncomplete,
      },
      icon: {
        active: colours.breadcrumbsIconActive,
        completed: colours.breadcrumbsIconCompleted,
        incomplete: colours.breadcrumbsIconIncomplete,
      },
      surface: {
        active: colours.breadcrumbsSurfaceActive,
        completed: colours.breadcrumbsSurfaceCompleted,
        incomplete: colours.breadcrumbsSurfaceIncomplete,
      },
      text: {
        active: colours.breadcrumbsTextActive,
        completed: colours.breadcrumbsTextCompleted,
        incomplete: colours.breadcrumbsTextIncomplete,
      },
    },
    border: {
      active: colours.borderActive,
      default: colours.borderDefault,
      success: colours.borderSuccess,
      critical: colours.borderCritical,
      interactive: colours.borderInteractive,
      information: colours.borderInformation,
      warning: colours.borderWarning,
      inverse: colours.borderInverse,
      strong: colours.borderStrong,
      subdued: colours.borderSubdued,
      logo: colours.logoBorder,
    },
    icons: {
      default: colours.iconDefault,
      disabled: colours.iconDisabled,
      inverse: colours.iconInverse,
      subdued: colours.iconSubdued,
      on: {
        success: colours.iconOnSuccess,
        critical: colours.iconOnCritical,
        information: colours.iconOnInformation,
        primary: {
          default: colours.iconOnPrimaryDefault,
          inverse: colours.iconOnPrimaryInverse,
        },
        secondary: {
          default: colours.iconOnSecondaryDefault,
          inverse: colours.iconOnSecondaryInverse,
        },
        tertiary: {
          default: colours.iconOnTertiaryDefault,
          inverse: colours.iconOnTertiaryInverse,
        },
        warning: colours.iconOnWarning,
      },
    },
    interactive: {
      focused: colours.interactiveFocused,
      primary: {
        default: colours.interactivePrimaryDefault,
        hover: colours.interactivePrimaryHover,
        pressed: colours.interactivePrimaryPressed,
        inverse: {
          default: colours.interactivePrimaryInverseDefault,
          hover: colours.interactivePrimaryInverseHover,
          pressed: colours.interactivePrimaryInversePressed,
        },
      },
      secondary: {
        default: colours.interactiveSecondaryDefault,
        hover: colours.interactiveSecondaryHover,
        pressed: colours.interactiveSecondaryPressed,
        border: colours.interactiveSecondaryBorder,
        inverse: {
          hover: colours.interactiveSecondaryInverseHover,
        },
      },
      tertiary: {
        hover: colours.interactiveTertiaryHover,
        inverse: {
          hover: colours.interactiveTertiaryInverseHover ?? '',
        },
      },
    },
    surface: {
      critical: colours.surfaceCritical,
      default: colours.surfaceDefault,
      disabled: colours.surfaceDisabled,
      hover: colours.surfaceHover,
      information: colours.surfaceInformation,
      inverse: colours.surfaceInverse,
      pressed: colours.surfacePressed,
      success: colours.surfaceSuccess,
      warning: colours.surfaceWarning,
      secondary: colours.surfaceSecondary,
      decorative: colours.surfaceDecorative,
      link: {
        hover: colours.surfaceLinkHover,
      },
      main: colours.surfaceMain,
      selected: {
        default: colours.surfaceSelectedDefault,
        hover: colours.surfaceSelectedHover,
        pressed: colours.surfaceSelectedPressed,
        subdued: colours.surfaceSelectedSubdued,
      },
    },
    text: {
      inverse: colours.textInverse,
      default: colours.textDefault,
      subdued: colours.textSubdued,
      critical: colours.textCritical,
      disabled: colours.textDisabled,
      heading: colours.textHeading,
      link: {
        default: colours.textLinkDefault,
        hover: colours.textLinkHover,
        pressed: colours.textLinkPressed,
      },
      on: {
        success: colours.textOnSuccess,
        critical: colours.textOnCritical,
        warning: colours.textOnWarning,
        information: colours.textOnInformation,
        interactive: {
          primary: {
            default: colours.textOnInteractivePrimaryDefault,
          },
          secondary: {
            default: colours.textOnInteractiveSecondaryDefault,
          },
          tertiary: {
            default: colours.textOnInteractiveTertiaryDefault,
          },
          toggle: {
            default: colours.textOnInteractiveToggleDefault,
            active: colours.textOnInteractiveToggleActive,
          },
        },
      },
    },
  };
};
