import { MouseEventHandler, ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeMarginProps } from '@ui-v2/types/props';
import { IconType } from '@ui-v2/types/typography';
import { StyledBadgeBox } from '../Badge/Badge';
import { buildBadgeIconConfig } from '../Badge/variants';
import Box from '../Box/Box';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

export type NotificationVariant =
  | 'information'
  | 'success'
  | 'warning'
  | 'critical';

export type Props = ThemeMarginProps & {
  as?: 'div' | 'span' | 'button';
  children?: ReactNode;
  icon?: IconType | null;
  onClick?: MouseEventHandler;
  onClose?: MouseEventHandler;
  size?: 'small' | 'regular';
  variant?: NotificationVariant;
};

const NotificationContainer = styled(StyledBadgeBox)<{
  variant: NotificationVariant;
}>(
  ({ theme, variant }) => css`
    border-left: 5px solid ${theme.colours.border[variant]};
  `,
);

const Notification = ({
  children,
  icon,
  onClose,
  size = 'regular',
  variant = 'information',
  ...props
}: Props) => {
  return (
    <NotificationContainer
      {...props}
      alignItems="center"
      borderRadius="borderRadiusM"
      display="flex"
      gap={size === 'regular' ? 16 : 8}
      p={size === 'regular' ? 16 : 8}
      position="relative"
      variant={variant}
    >
      {onClose && (
        <Box position="absolute" right={8} top={4}>
          <Button
            icon="closeIcon"
            onClick={onClose}
            size="iconExtraSmall"
            variant="tertiary"
          />
        </Box>
      )}
      {icon && (
        <Box flex="0 0 auto">
          <Icon
            size={size === 'regular' ? 20 : 14}
            type={icon}
            {...buildBadgeIconConfig(variant)}
          />
        </Box>
      )}
      {children}
    </NotificationContainer>
  );
};

export default Notification;
