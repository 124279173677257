import { css } from '@emotion/react';

const skyteamFonts = css`
  @font-face {
    font-family: Diavlo;
    src: url('/fonts/SkyTeam/diavlo.woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/SkyTeam/OpenSans.woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    src: url('/fonts/SkyTeam/OpenSans-Bold.woff2');
  }
`;

export default skyteamFonts;
